import Typography from '@mui/material/Typography';

type ParagraphTitleProps = {
  text: string,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
  title?: string,
  padding?: string,
};

function SharedParagraphTitle({ text, align, title, padding }: ParagraphTitleProps) {
  return (
    <Typography
      variant="subtitle2"
      align={align}
      title={title}
      padding={padding}
    >
      <b>{text}</b>
    </Typography>
  );
}

export default SharedParagraphTitle;
