import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

type SharedLoadableTemplateProps = {
  children: React.ReactNode,
};

function SharedLoadableTemplate({ children }: SharedLoadableTemplateProps) {
  return (
    <Suspense fallback={<CircularProgress />}>
      {children}
    </Suspense>
  );
}

export default SharedLoadableTemplate;
