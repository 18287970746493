import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    app: {
      cardHeaderHeight: React.CSSProperties['height'],
      headerBackgroundColor: React.CSSProperties['color'],
      border: React.CSSProperties['border'],
      inputBorder: React.CSSProperties['border'],
    }
  }
  interface ThemeOptions {
    app?: {
      cardHeaderHeight?: React.CSSProperties['height'],
      headerBackgroundColor?: React.CSSProperties['color'],
      border?: React.CSSProperties['border'],
      inputBorder?: React.CSSProperties['border'],
    }
  }
}

const primaryColor = '#2e88e0';
const darkColor = '#1e1c1f';
const backgroundColor = '#f4f6f8';
const cardHeaderHeight = 40;
const paragraphFontSize = '0.875rem';
const bottomShadow = '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)';

export function sharedCreateTheme() {
  const border = '1px solid #e2e2e2';
  const headerBackgroundColor = '#fafafa';
  const inputBorder = '1px solid #c4c4c4';
  const options: ThemeOptions = {
    palette: {
      primary: {
        main: primaryColor,
        dark: darkColor,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar': {
            width: 8,
            height: 8,
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: 'inherit',
          },
          '*::-webkit-scrollbar-corner': {
            backgroundColor: 'inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#d6dee1',
            borderRadius: 8,
            border: '1px solid transparent',
            backgroundClip: 'content-box',
          },
          body: {
            backgroundColor: backgroundColor,
            minWidth: 300,
          },
          a: {
            textDecoration: 'none',
            outline: 'none',
            color: '#2196f3',
            fontWeight: 400,
          },
          '.shared-pill': {
            borderRadius: 15,
            padding: '1px 8px',
            whiteSpace: 'noWrap',
            position: 'relative',
            fontSize: '0.8rem',
          },
          '.shared-pill-info-icon::after': {
            content: '"!"',
            padding: '0px 6px',
            position: 'absolute',
            background: 'red',
            fontWeight: 'bold',
            fontSize: '0.9rem',
            lineHeight: '1rem',
            borderRadius: '50%',
            marginLeft: 1,
            marginTop: 2,
            boxShadow: '0 1px 3px 0 rgb(0 0 0 / 20%), -2px 0px 1px 0 rgb(0 0 0 /19%)',
            top: -6,
            right: -10,
          },
          '.shared-bold': {
            fontWeight: 700,
          },
          input: {
            fontSize: '1rem',
            fontFamily: 'Roboto',
          },
          'input::placeholder': {
            opacity: 0.5,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            height: '100%',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            boxShadow: bottomShadow,
            maxHeight: cardHeaderHeight,
            marginBottom: 2,
            '& .MuiTypography-h5': {
              fontSize: '0.875rem',
              fontWeight: 700,
            },
          },
          action: {
            marginTop: '-14px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            fontSize: paragraphFontSize,
            height: `calc(100% - ${cardHeaderHeight}px - 2px)`,
            overflow: 'auto',
            '&:last-child': {
              paddingBottom: 0,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '10px 16px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          dividers: {
            padding: '10px 16px',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            maxHeight: cardHeaderHeight,
            minHeight: cardHeaderHeight,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: cardHeaderHeight,
            minWidth: '100px !important',
            textTransform: 'none',
          },
          textColorPrimary: {
            color: 'black',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: paragraphFontSize,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            '& .MuiIconButton-root': {
              padding: '0 5px',
            },
            '& .MuiTablePagination-toolbar': {
              minHeight: '30px',
            },
            '& .MuiTablePagination-displayedRows': {
              margin: 0,
            },
          },
          toolbar: {
            borderTop: border,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 0,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          endAdornment: {
            right: '2px !important',
          },
          clearIndicator: {
            marginRight: '-6px !important',
            '& .MuiSvgIcon-fontSizeSmall': {
              fontSize: '1rem',
            },
          },
          hasClearIcon: {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
              paddingRight: '40px !important',
            },
          },
        },
      },
    },
  };
  return createTheme({
    app: {
      cardHeaderHeight: cardHeaderHeight,
      headerBackgroundColor: headerBackgroundColor,
      border: border,
      inputBorder: inputBorder,
    },
    ...options,
  })
}
