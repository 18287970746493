import { useAuth0 } from '@auth0/auth0-react';
import SharedPageLoader from '@shared/layout/PageLoader';


function SharedAuthCallback() {
  const { error } = useAuth0();
  return <SharedPageLoader error={error} />;
}

export default SharedAuthCallback;
