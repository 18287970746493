import { sharedSetLocalStorageData } from '../../utils/localStorage';
import { SharedLayoutState, SharedLayoutPageState } from './layout-store';

export const SHARED_STORAGE_CONTENT_FILTERS_OPEN = 'shared-content-filters-open';
export const SHARED_STORAGE_NAVIGATION_OPEN = 'shared-navigation-open';

export type SharedLayoutAction = {
  type: 'SHARED_LAYOUT_HAS_FILTERS' | 'SHARED_LAYOUT_TOGGLE_FILTERS' | 'SHARED_LAYOUT_TOGGLE_NAVIGATION',
  payload: boolean,
} | {
  type: 'SHARED_LAYOUT_CHANGE_TITLE' | 'SHARED_LAYOUT_CHANGE_ROUTE_GROUP',
  payload: string,
} | {
  type: 'SHARED_LAYOUT_CLEAR_FILTERS',
} | {
  type: 'SHARED_LAYOUT_CHANGE_PAGE_INFO',
  payload: SharedLayoutPageState,
};

const sharedLayoutReducer = (state: SharedLayoutState, action: SharedLayoutAction) => {
  switch (action.type) {
    case 'SHARED_LAYOUT_TOGGLE_FILTERS':
      sharedSetLocalStorageData(SHARED_STORAGE_CONTENT_FILTERS_OPEN, action.payload);
      return {
        ...state,
        filtersOpen: action.payload,
      };
    case 'SHARED_LAYOUT_TOGGLE_NAVIGATION':
      sharedSetLocalStorageData(SHARED_STORAGE_NAVIGATION_OPEN, action.payload);
      return {
        ...state,
        navigationOpen: action.payload,
      };
    case 'SHARED_LAYOUT_HAS_FILTERS':
      return {
        ...state,
        hasFilters: action.payload,
      };
    case 'SHARED_LAYOUT_CHANGE_TITLE':
      document.title = action.payload;
      return {
        ...state,
        title: action.payload,
      };
    case 'SHARED_LAYOUT_CHANGE_ROUTE_GROUP':
      return {
        ...state,
        activeRouteGroup: action.payload,
      };
    case 'SHARED_LAYOUT_CLEAR_FILTERS':
      return {
        ...state,
        clearFilters: !state.clearFilters,
      };
    case 'SHARED_LAYOUT_CHANGE_PAGE_INFO':
      if (action.payload.title) {
        document.title = action.payload.title;
      }
      return {
        ...state,
        title: action.payload.title,
        activeRouteGroup: action.payload.activeRouteGroup,
        hasFilters: action.payload.hasFilters,
      };
    default:
      return state;
  }
};

export default sharedLayoutReducer;
