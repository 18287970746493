import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SharedLayoutContext } from '../store/layout-store';

export const FILTERS_DRAWER_WIDTH = 300;

type SharedToolbarProps = {
  onDrawerOpen: () => void,
  menu?: React.ReactNode,
  navigationDrawerWidth: number,
};

function SharedToolbar({ menu, onDrawerOpen, navigationDrawerWidth }: SharedToolbarProps) {
  const { state, dispatch } = useContext(SharedLayoutContext);

  const handleOpenFilters = () => {
    dispatch({ type: 'SHARED_LAYOUT_TOGGLE_FILTERS', payload: true });
  };
  const filtersOpen = state.hasFilters && state.filtersOpen;
  return (
    <AppBar
      position="fixed"
      sx={{
        background: 'white',
        color: 'black',
        paddingLeft: theme => theme.spacing(1),
        width: {
          'xs': '100%',
          'md': filtersOpen ? `calc(100% - ${FILTERS_DRAWER_WIDTH}px - ${navigationDrawerWidth}px)` : `calc(100% - ${navigationDrawerWidth}px)`,
        },
        marginLeft: {
          'xs': 0,
          'md': `${navigationDrawerWidth}px`,
        },
        marginRight: {
          'xs': 0,
          'md': filtersOpen ? `${FILTERS_DRAWER_WIDTH}px` : 0,
        },
      }}
    >
      <Toolbar variant="dense" disableGutters>
        <IconButton
          edge="start"
          onClick={onDrawerOpen}
          sx={{
            marginLeft: -4,
            display: {
              'md': 'none',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1, marginLeft: 1, fontSize: '1.1rem' }}>
          {state.title}
        </Typography>
        {state.hasFilters && !state.filtersOpen && (
          <IconButton title="Show Filters" onClick={handleOpenFilters}>
            <FilterListIcon />
          </IconButton>
        )}
        {menu}
      </Toolbar>
    </AppBar>
  );
}

export default SharedToolbar;
