import MenuItem from '@mui/material/MenuItem';

type SharedMenuItemProps = {
  children: string | React.ReactNode,
  onClick: (action?: () => void) => void,
  action?: () => void,
};

function SharedMenuItem({ children, onClick, action }: SharedMenuItemProps) {
  const handleClick = () => {
    onClick(action);
  };

  return (
    <MenuItem onClick={handleClick} children={children} />
  );
}

export default SharedMenuItem;
