import React from 'react';
import SharedLoadableTemplate from '@shared/layout/content/LoadableTemplate';

const LoadableComponent = React.lazy(() => import('./CompetitorsDetails'));

function LoadableCompetitorsDetails() {
  return (
    <SharedLoadableTemplate>
      <LoadableComponent />
    </SharedLoadableTemplate>
  );
}

export default LoadableCompetitorsDetails;
