import React from 'react';
import SharedLoadableTemplate from '@shared/layout/content/LoadableTemplate';

const LoadableComponent = React.lazy(() => import('./CompetitorsList'));

function LoadableCompetitorsList() {
  return (
    <SharedLoadableTemplate>
      <LoadableComponent />
    </SharedLoadableTemplate>
  );
}

export default LoadableCompetitorsList;
