import SharedAuthLogout from '@shared/auth/AuthLogout';
import SharedLayout from '@shared/layout/Layout';
import { SharedRouteGroup } from '@shared/layout/navigation/NavigationMenuItem';

type AppLayoutProps = {
  children: React.ReactNode,
  routeGroups: SharedRouteGroup[],
};

function AppLayout({ children, routeGroups }: AppLayoutProps) {
  return (
    <SharedLayout
      title="Greg's Automotive"
      routeGroups={routeGroups}
      menu={<SharedAuthLogout />}
      children={children}
    />
  );
}
export default AppLayout;
