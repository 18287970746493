import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import SharedMenuItem from './MenuItem';

export type SharedMenuOption = {
  children?: string | React.ReactNode,
  onClick?: () => void,
  divider?: boolean,
};

type SharedMenuProps = {
  menu: SharedMenuOption[],
  isProfileMenu?: boolean,
};

function SharedMenu({ menu, isProfileMenu }: SharedMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (action?: () => void) => {
    if (action) {
      action();
      handleClose();
    }
  };
  const menuIcon = isProfileMenu ? (
    <IconButton onClick={handleOpen}>
      <AccountCircleIcon />
    </IconButton>
  ) : (
    <IconButton onClick={handleOpen} size="small">
      <MoreVertIcon />
    </IconButton>
  );

  if (menu.length === 0) {
    return <></>;
  }
  return (
    <>
      {menuIcon}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl !== null}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
      >
        {menu.map((item, index) => (
          <span key={index}>
            {item.children && (
              <SharedMenuItem children={item.children} action={item.onClick} onClick={handleMenuClick} />
            )}
            {item.divider && <Divider />}
          </span>
        ))}
      </Menu>
    </>
  );
}

export default SharedMenu;
