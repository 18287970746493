import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import SharedPageLoader from '@shared/layout/PageLoader';

type SharedAuthGuardProps = {
  component: React.ComponentType,
};

function SharedAuthGuard({ component }: SharedAuthGuardProps) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <SharedPageLoader />
    ),
  });

  return <Component />;
}

export default SharedAuthGuard;
