import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SharedNavigationMenuItem, { SharedRouteGroup } from './NavigationMenuItem';

export type SharedNavigationMenuProps = {
  title: string,
  routeGroups: SharedRouteGroup[],
};

function SharedNavigationMenu({ title, routeGroups }: SharedNavigationMenuProps) {
  return (
    <List sx={{ paddingTop: 0 }} disablePadding>
      <ListItem style={{
        color: 'white',
        paddingRight: '2px',
        paddingTop: '6px',
        paddingBottom: '6px',
      }}>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ variant: 'subtitle1' }}
          sx={{ color: 'white' }}
        />
      </ListItem>
      {routeGroups.map((item, index) => <SharedNavigationMenuItem key={index} group={item} />)}
    </List>
  );
}

export default SharedNavigationMenu;
