import ListItemText from '@mui/material/ListItemText';
import { useAuth0 } from '@auth0/auth0-react';
import SharedMenu, { SharedMenuOption } from '@shared/menu/Menu';


function SharedAuthLogout() {
  const { isAuthenticated, logout, user } = useAuth0();

  if (!isAuthenticated) {
    return <></>;
  }

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const menu: SharedMenuOption[] = [
    { children: <ListItemText primary={user?.name} /> },
    { divider: true },
    { children: 'Logout', onClick: handleLogout }
  ];
  return <SharedMenu menu={menu} isProfileMenu />;
}

export default SharedAuthLogout;
