import { HashRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import SharedLayoutProvicer from '@shared/layout/store/layout-store';
import SharedAuthProvider from '@shared/auth/AuthProvider';
import { sharedCreateTheme } from '@shared/theme';
import AppRoutes from './AppRoutes';

const appTheme = sharedCreateTheme();

function App() {
  return (
    <SharedLayoutProvicer>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <HashRouter>
          <SharedAuthProvider>
            <AppRoutes />
          </SharedAuthProvider>
        </HashRouter>
      </ThemeProvider>
    </SharedLayoutProvicer>
  );
}

export default App;
