import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SharedNavigationMenu, { SharedNavigationMenuProps } from './NavigationMenu';

type SharedNavigationProps = {
  open: boolean,
  navigationDrawerWidth: number,
  window?: Function,
  onDrawerClose: () => void,
} & SharedNavigationMenuProps;

function SharedNavigation({ title, routeGroups, open, onDrawerClose, window, navigationDrawerWidth }: SharedNavigationProps) {
  const container = window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();
  const showPermanentDrawer = useMediaQuery(theme.breakpoints.up('md'));
  const navigationMenu = (
    <SharedNavigationMenu
      title={title}
      routeGroups={routeGroups}
    />
  );
  const drawer = showPermanentDrawer ? (
    <Drawer
      variant="permanent"
      open
      children={navigationMenu}
      sx={{
        '& .MuiDrawer-paper': {
          width: `${navigationDrawerWidth}px`,
          background: theme.palette.primary.dark,
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'inherit',
          },
        },
      }}
    />
  ) : (
    <Drawer
      container={container}
      variant="temporary"
      open={open}
      onClose={onDrawerClose}
      children={navigationMenu}
      sx={{
        '& .MuiDrawer-paper': {
          width: `${navigationDrawerWidth}px`,
          background: theme.palette.primary.dark,
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'inherit',
          },
        },
      }}
      ModalProps={{
        keepMounted: true
      }}
    />
  );
  return (
    <Box
      component="nav"
      sx={{
        width: {
          'xs': 0,
          'md': `${navigationDrawerWidth}px`,
        },
        flexShrink: 0,
        marginLeft: {
          'xs': 0,
        },
      }}
    >
      {drawer}
    </Box>
  );
}

export default SharedNavigation;
