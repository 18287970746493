import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import './app/App.scss'
import '@fontsource/roboto';

const rootElement = document.getElementById('root');
if (/MSIE|Trident/.test(window.navigator.userAgent)) {
  document.body.classList.add('old-ie');
} else if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
