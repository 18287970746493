import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LayersIcon from '@mui/icons-material/Layers';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { SharedRouteGroup } from '@shared/layout/navigation/NavigationMenuItem';
import LoadableCompetitorsInventoryList from '@app-competitors-inventory-list/LoadableCompetitorsInventoryList';
import LoadableCompetitorsList from 'src/pages/competitors/competitors/list/LoadableCompetitorsList';
import LoadableCompetitorsDetails from 'src/pages/competitors/competitors/details/LoadableCompetitorsDetails';
import LoadableCompetitorsVehiclePricer from 'src/pages/competitors/vehiclePricer/LoadableCompetitorsVehiclePricer';
import SharedAuthGuard from '@shared/auth/AuthGuard';
import SharedAuthCallback from '@shared/auth/AuthCallback';
import AppLayout from './AppLayout';

export type RouterParams = {
  id: string,
};

export enum RoutePath {
  CompetitorsList = '/competitors/list',
  CompetitorsDashboard = '/competitors/dashboard',
  CompetitorsInventory = '/competitors/inventory',
  CompetitorsVehiclePricer = '/competitors/price-discovery',
};

export function getRouteGroups(): SharedRouteGroup[] {
  return [{
    title: 'Competitors',
    id: 'competitors',
    routes: [
      /*{
        path: RoutePath.CompetitorsDashboard,
        exact: true,
        title: 'Dashboard',
        element: <LoadableCompetitorsDashboard />,
        icon: <DashboardIcon />,
      },*/
      {

        path: RoutePath.CompetitorsList,
        exact: false,
        title: 'Competitors',
        icon: <LayersIcon />,
        children: [
          {
            index: true,
            element: () => <LoadableCompetitorsList />,
          },
          {
            path: ':id',
            element: () => <LoadableCompetitorsDetails />,
          },
        ],
      },
      {

        path: RoutePath.CompetitorsInventory,
        exact: true,
        title: 'Inventory',
        element: () => <LoadableCompetitorsInventoryList />,
        icon: <DirectionsCarIcon />,
      },
      {

        path: RoutePath.CompetitorsVehiclePricer,
        exact: true,
        title: 'Price Discovery',
        element: () => <LoadableCompetitorsVehiclePricer />,
        icon: <AttachMoneyIcon />,
      },
    ],
  }];
}

const routeGroups = getRouteGroups();
const routes = routeGroups.map(routeGroup => routeGroup.routes).flat();

function AppRoutes() {
  return (
    <Routes>
      {routes.map((route, index) => !route.children ? (
        <Route
          key={index}
          path={route.path}
          element={<SharedAuthGuard component={() => <AppLayout routeGroups={routeGroups} children={route.element && <route.element />} />} />}
        />
      ) : (
        <Route key={index} path={route.path}>
          {route.children.map((child, indexChild) => (
            <Route
              key={`${index}_${indexChild}`}
              index={child.index}
              path={!child.index ? child.path : undefined}
              element={<SharedAuthGuard component={() => <AppLayout routeGroups={routeGroups} children={<child.element />} />} />}
            />
          ))}
        </Route>
      ))}
      <Route path="/callback" element={<SharedAuthCallback />} />
      <Route path="*" element={<Navigate to={routes[0].path} replace />} />
    </Routes>
  );
}

export default AppRoutes;
