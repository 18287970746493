import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { ERROR_GENERIC } from '@shared/data/constants';
import SharedParagraphTitle from '@shared/typography/ParagraphTitle';

type SharedPageLoaderProps = {
  logo?: React.ReactNode,
  error?: any,
};

function SharedPageLoader({ logo, error }: SharedPageLoaderProps) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: '100vh',
        textAlign: 'center',
      }}
    >
      <Grid item xs={3}>
        <div style={{ marginBottom: 5 }}>
          {logo}
        </div>
        {error ? <SharedParagraphTitle text={ERROR_GENERIC} /> : <CircularProgress size={30} />}
      </Grid>
    </Grid>
  );
}

export default SharedPageLoader;
