import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SharedNavigationMenuItemLink, { SharedRoute } from './NavigationMenuItemLink';

export type SharedRouteGroup = {
  title: string,
  id: string,
  routes: SharedRoute[],
  hidden?: boolean,
};

type SharedNavigationMenuItemProps = {
  group: SharedRouteGroup,
};

function SharedNavigationMenuItem({ group }: SharedNavigationMenuItemProps) {
  return (
    <>
      {!group.hidden && (
        <ListItem
          sx={{
            color: 'white',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        >
          <ListItemText primary={group.title} primaryTypographyProps={{ variant: 'body2' }} />
        </ListItem>
      )}
      {group.routes?.map((route, index) => (
        <SharedNavigationMenuItemLink key={index} route={route} />
      ))}
    </>
  );
}

export default SharedNavigationMenuItem;
