import { useReducer, createContext } from 'react';
import { sharedGetLocalStorageData } from '../../utils/localStorage';
import { SharedContext, SharedStoreProviderProps } from '../../data/store/types';
import sharedLayoutReducer, {
  SharedLayoutAction,
  SHARED_STORAGE_CONTENT_FILTERS_OPEN,
  SHARED_STORAGE_NAVIGATION_OPEN
} from './layout-reducer';

export type SharedLayoutPageState = {
  title?: string,
  activeRouteGroup?: string,
  hasFilters: boolean,
};

export type SharedLayoutState = {
  filtersOpen: boolean,
  navigationOpen: boolean,
  clearFilters?: boolean,
} & SharedLayoutPageState;

function getEmptyState(): SharedLayoutState {
  const filtersOpen = sharedGetLocalStorageData(SHARED_STORAGE_CONTENT_FILTERS_OPEN, 'boolean');
  const navigationOpen = sharedGetLocalStorageData(SHARED_STORAGE_NAVIGATION_OPEN, 'boolean');
  return {
    filtersOpen: filtersOpen !== undefined ? filtersOpen : true,
    navigationOpen: navigationOpen !== undefined ? navigationOpen : true,
    hasFilters: false,
  };
}

export const SharedLayoutContext = createContext({} as SharedContext<SharedLayoutState, SharedLayoutAction>);

const SharedLayoutProvicer = ({ children }: SharedStoreProviderProps) => {
  const [state, dispatch] = useReducer(sharedLayoutReducer, getEmptyState());
  return <SharedLayoutContext.Provider value={{ state, dispatch }} children={children} />
};

export default SharedLayoutProvicer;
