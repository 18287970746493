type LocalStorageType = 'string' | 'number' | 'array' | 'object' | 'boolean';

export function sharedGetType(value: any) {
  let match = ({}).toString.call(value).match(/\s([a-zA-Z]+)/);
  if (!match) {
    return null;
  }
  return match[1].toLowerCase();
}

function getStorageData(storage: Storage, key: string, type: LocalStorageType) {
  let value: any;
  try {
    value = JSON.parse(storage[key]);
  } catch (e) {
    value = undefined;
  }
  return (value === null || sharedGetType(value) === type) ? value : undefined;
}

function setStorageData(storage: Storage, key: string, value: any) {
  storage.setItem(key, JSON.stringify(value));
}

export function sharedGetLocalStorageData(key: string, type: LocalStorageType) {
  return getStorageData(localStorage, key, type);
}

export function sharedSetLocalStorageData(key: string, value: any) {
  setStorageData(localStorage, key, value);
}

export function sharedGetSessionStorageData(key: string, type: LocalStorageType) {
  return getStorageData(sessionStorage, key, type);
}

export function sharedSetSessionStorageData(key: string, value: any) {
  setStorageData(sessionStorage, key, value);
}
