import React, { useContext, useEffect, useState } from 'react';
import { SharedLayoutContext } from './store/layout-store';
import { CssBaseline } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import SharedNavigation from './navigation/Navigation';
import { SharedRouteGroup } from './navigation/NavigationMenuItem';
import SharedToolbar from './toolbar/Toolbar';

export type SharedLayoutProps = {
  children: React.ReactNode,
  title: string,
  routeGroups: SharedRouteGroup[],
  menu?: React.ReactNode,
  navigationDrawerWidth?: number,
};

function SharedLayout({ children, title, routeGroups, menu, navigationDrawerWidth = 185 }: SharedLayoutProps) {
  const theme = useTheme();
  const { state, dispatch } = useContext(SharedLayoutContext);
  const [open, setOpen] = useState(state.navigationOpen);

  const handleDrawerOpen = () => {
    dispatch({ type: 'SHARED_LAYOUT_TOGGLE_NAVIGATION', payload: true });
  };

  const handleDrawerClose = () => {
    dispatch({ type: 'SHARED_LAYOUT_TOGGLE_NAVIGATION', payload: false });
  };

  useEffect(
    () => {
      setOpen(state.navigationOpen);
    },
    [state.navigationOpen]
  );

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <SharedToolbar
        onDrawerOpen={handleDrawerOpen}
        menu={menu}
        navigationDrawerWidth={navigationDrawerWidth}
      />
      <SharedNavigation
        title={title}
        routeGroups={routeGroups}
        onDrawerClose={handleDrawerClose}
        open={open}
        navigationDrawerWidth={navigationDrawerWidth}
      />
      <main style={{
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        overflowX: 'hidden',
      }}>
        <div style={{ minHeight: theme.spacing(6) }} />
        {children}
      </main>
    </div>
  );
}

export default SharedLayout;
