import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import useTheme from '@mui/material/styles/useTheme';
import grey from '@mui/material/colors/grey';

export type SharedChildRoute = {
  element: () => JSX.Element,
  path?: string,
  index?: boolean,
};

export type SharedRoute = {
  path: string,
  element?: () => JSX.Element,
  title?: string,
  exact?: boolean,
  hidden?: boolean,
  icon?: React.ReactNode,
  children?: SharedChildRoute[],
};

type SharedNavigationMenuItemLinkProps = {
  route: SharedRoute,
};

function SharedNavigationMenuItemLink({ route }: SharedNavigationMenuItemLinkProps) {
  const theme = useTheme();

  return (
    <NavLink
      to={route.path}
      end={route.exact}
      style={({ isActive }) => ({
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',
        borderLeft: '4px solid transparent',
        color: isActive ? 'white' : grey[500],
        transition: theme.transitions.create(['color']),
        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.4)' : 'inherit',
        borderLeftColor: isActive ? theme.palette.primary.main : 'transparent',
        '&:hover': {
          color: 'white',
          backgroundColor: isActive ? 'rgba(0, 0, 0, 0.6)' : 'inerit',
        },
      })}
    >
      {route.icon &&
        <ListItemIcon
          sx={{ minWidth: '30px', marginLeft: '10px', color: 'inherit' }}
          children={route.icon}
        />
      }
      <ListItem sx={{ paddingLeft: '5px' }}>
        <ListItemText primary={route.title} primaryTypographyProps={{ variant: 'body2' }} />
      </ListItem>
    </NavLink>
  );
}

export default SharedNavigationMenuItemLink;
